.tech3 {
  fill: #106ba3;
}
.tech3-stroke {
  stroke: #106ba3;
  fill: none;
}
.headerSkill {
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
}
