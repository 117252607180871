.ajov-profile-pic {
  margin-top: -100px;
}

.profile-about-card {
  position: absolute;
  top: -2200px;
  z-index: 22;
}

.ajov-experience-pic {
  margin-top: -100px;
}

.experience-about-card {
  position: absolute;
  top: 100px;
  left: -2200px;
  z-index: 22;
}

.ajov-build-pic {
  margin-top: -100px;
}

.build-about-card {
  position: absolute;
  top: 100px;
  left: 5300px;
  z-index: 22;
}

.ajov-tinker-pic {
  margin-top: -100px;
}

.tinker-about-card {
  position: absolute;
  top: 5300px;
  z-index: 22;
}


@media only screen and (max-width: 1920px) {
  .experience-about-card {
    top: 60px;
  }
}