#headerbluelinesajov {
  position: absolute;
  left: 70%;
  top: 10px;
  z-index: 10;
  width: 70%;
}
.header-bluelines- {
  fill: #0094d9;
}
