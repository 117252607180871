#main-background {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 45%;
  height: 45%;
  z-index: 2;
}


.bg-ajov-1 {
  fill: #485e87;
}

.bg-ajov-1,
.bg-ajov-2,
.bg-ajov-3,
.bg-ajov-4 {
  fill-rule: evenodd;
}

.bg-ajov-2 {
  fill: #d0d2d3;
}

.bg-ajov-3 {
  fill: #bbbdbf;
}

.bg-ajov-4 {
  fill: #0094d9;
}
