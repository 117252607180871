#brandNavigation {
  position: fixed;
  top: 10px;
  right: 500px;
  width: 200px;
  height: 200px;
  z-index: 20;
}

.brandbox-1,
.brandbox-4 {
  fill: #fff;
}

.brandbox-2 {
  fill: #4d4d4d;
}

.brandbox-3 {
  fill: #0e78b6;
  stroke: #030102;
  stroke-width: 4px;
}

.brandbox-3,
.brandbox-4,
.brandbox-5 {
  fill-rule: evenodd;
}

.brandbox-5 {
  fill: #231f20;
}

/* its fucking retarted this css rules across arrogant framework just dont accept certain queries VERY STUPID 
 '2xl': {'max': '1535px'},
      // => @media (max-width: 1535px) { ... }

      'xl': {'max': '1279px'},
      // => @media (max-width: 1279px) { ... }

      'lg': {'max': '1023px'},
      // => @media (max-width: 1023px) { ... }

      'md': {'max': '767px'},
      // => @media (max-width: 767px) { ... }

      'sm': {'max': '639px'},
      // => @media (max-width: 639px) { ... }

      'xs': {'max': '419px'},
      // => @media (max-width: 419px) { ... }
*/
@media only screen and (max-width: 639px) {
  #brandNavigation {
    transform: scale(0.5);
    left: -20px;
    top: -30px;
  }
}

/* @media only screen and (max-width: 419px) {
  #brandNavigation {
    transform: scale(0.5);
    left: -20px;
    top: -30px;
  }
} */