#headersquareajov {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 10;
}

#headersquareajov svg {
  width: 320%;
}

@-moz-document url-prefix() {
  #headersquareajov svg {
    width: 100%;
  }
}

.header-squares-cls-1 {
  fill: #c6c6c5;
}

.header-squares-cls-1,
.header-squares-cls-2,
.header-squares-cls-3,
.header-squares-cls-4 {
  fill-rule: evenodd;
}

.header-squares-cls-2 {
  fill: #008ad1;
}

.header-squares-cls-3 {
  fill: #6f6f6e;
}

.header-squares-cls-4 {
  fill: #53668c;
}

@media only screen and (max-width: 1920px) {
  #headersquareajov svg {
    width: 200%;
  }

  @-moz-document url-prefix() {
    #headersquareajov svg {
      width: 100%;
    }
  }
  
}