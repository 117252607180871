.ajovloader {
  margin:0px;
  text-align:center;
  padding-top:43vh;
  padding-bottom:63vh;
  color:#fff;
  font-size:36px;
  font-weight:100;
  overflow:hidden;
  background-color: #0094ff;
}

.ajoload {
    display: block;
    margin-bottom: 10px;

}

.glowskill {
    animation: neon1 1.5s ease-in-out infinite alternate;
}

.glowajov {
    animation: neon3 1.5s ease-in-out infinite alternate;
}

.dropping-texts {
  display: inline-block;
  width: 180px;
  text-align: left;
  height: 36px;
  vertical-align: -2px;
}

.dropping-texts > div {
  font-size:0px;
  opacity:0;
  margin-left:-30px;
  position:absolute;
  font-weight:600;   
  box-shadow: 0px 60px 25px -20px rgba(0,0,0,0.5);
}

.dropping-texts > div:nth-child(1) {
  animation: roll 5s linear infinite 0s;
}
.dropping-texts > div:nth-child(2) {
  animation: roll 5s linear infinite 1s;
}
.dropping-texts > div:nth-child(3) {
  animation: roll 5s linear infinite 2s;
}
.dropping-texts > div:nth-child(4) {
  animation: roll 5s linear infinite 3s;
}

@keyframes roll {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top: -10px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:-10px;
  }
  20% {
    font-size:45px;
    opacity:1;
    margin-left:0px;
    margin-top:-10px;
    transform: rotate(0deg);
  }
  27% {
    font-size:0px;
    opacity:0.5;
    margin-left:20px;
    margin-top:100px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes roll2 {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  30% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  37% {
    font-size:1500px;
    opacity:0;
    margin-left:-1000px;
    margin-top:-800px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes bg {
  0% {background: #ff0075;}
  3% {background: #0094ff;}
  20% {background: #0094ff;}
  23% {background: #b200ff;}
  40% {background: #b200ff;}
  43% {background: #8BC34A;}
  60% {background: #8BC34A;}
  63% {background: #F44336;}
  80% {background: #F44336;}
  83% {background: #F44336;}
  100% {background: #F44336;}
}

@keyframes neon3 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ffdd1b,
        0 0 70px #ffdd1b, 0 0 80px #ffdd1b, 0 0 100px #ffdd1b, 0 0 150px #ffdd1b;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ffdd1b,
        0 0 35px #ffdd1b, 0 0 40px #ffdd1b, 0 0 50px #ffdd1b, 0 0 75px #ffdd1b;
    }
  }

  @keyframes neon1 {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
          0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
      }
      to {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
          0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
      }
  }