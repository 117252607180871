.video-container{
    width: 100vw;
    height: 10vh;
  }

  .wrapperPort {
    display: relative;
  }

  .mainPortVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
  }
  

  .tl-timeline {
    background: none;
  }

  .tl-slider-background  {
    background-color: rgba(131, 131, 235, 0.5) !important; 
  }