/*	Reset
------------------------------------------------------------------------------------------- */

.tl-storyjs {

	/*	Reset tags and common classes
		Display in IE6-9 and FF3
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
	
	div * {
		-webkit-box-sizing:content-box;
		   -moz-box-sizing:content-box;
		        box-sizing:content-box;
	}
	
	h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, 
	sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td, 
	.tl-container, .content-container, .media, .text, .tl-slider, .slider, .date, .title, .message, .map, .credit, .caption, .tl-feedback, .tl-feature, .toolbar, 
	.marker, .dot, .line, .flag, .time, .era, .major, .minor, .tl-navigation, .start, .active  {
		margin: 0;
		padding: 0;
		border: 0;
		font-weight: normal;
		font-style: normal;
		font-size: 100%;
		line-height: 1;
		font-family: inherit;
		width: auto;
		float:none;
	}
	h1, h2, h3, h4, h5, h6 {
		clear:none;
	}
	table { border-collapse: collapse; border-spacing: 0;}
	ol, ul { list-style: none;}
	q:before, q:after, blockquote:before, blockquote:after { content: ""; }
	a:focus { outline: thin dotted; }
	a:hover, a:active { outline: 0;}
	article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
		display: block;
	}
	audio, canvas, video {
		display: inline-block;
	}

	/*	Prevents modern browsers from displaying 'audio' without controls
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
	audio:not([controls]) {
		display: none;
	}
	div {
		max-width:none;
	}
	
	/*	Prevents sub and sup affecting line-height in all browsers
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
	sub, sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}
	sup {
		top: -0.5em;
	}
	sub {
		bottom: -0.25em;
	}

	/*	Img border in a's and image quality
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
	img {
		border: 0;
		-ms-interpolation-mode: bicubic;
	}

	/*	Forms
		Based on: http://github.com/necolas/normalize.css
	------------------------------------------------------------------------------------------- */
	button, input, select, textarea {
	  font-size: 100%;
	  margin: 0;
	  vertical-align: baseline;
	  *vertical-align: middle;
	}
	button, input {
	  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
	  *overflow: visible; // Inner spacing ie IE6/7
	}
	button::-moz-focus-inner, input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
	  border: 0;
	  padding: 0;
	}
	button, input[type="button"], input[type="reset"], input[type="submit"] {
	  cursor: pointer; // Cursors on all buttons applied consistently
	  -webkit-appearance: button; // Style clicable inputs in iOS
	}
	input[type="search"] { // Appearance in Safari/Chrome
	  -webkit-appearance: textfield;
	  -webkit-box-sizing: content-box;
	     -moz-box-sizing: content-box;
	          box-sizing: content-box;
	}
	input[type="search"]::-webkit-search-decoration {
	  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
	}
	textarea {
	  overflow: auto; // Remove vertical scrollbar in IE6-9
	  vertical-align: top; // Readability and alignment cross-browser
	}
}