.about1 {
  position: absolute;
  left: 5px;
  bottom: 248px;
  z-index: 12;
}

.about2 {
  position: absolute;
  left: 34px;
  bottom: 507px;
  z-index: 12;
}

.about-button-wrapper-2 {
  height: 60px;
}

.about-button-2 {
  width: 60px;
  height: 60px;
}

.about3 {
  position: absolute;
  left: 270px;
  bottom: 505px;
  z-index: 12;
}

.about-button-wrapper-3 {
  height: 60px;
}

.about-button-3 {
  width: 60px;
  height: 60px;
}

.about4 {
  position: absolute;
  left: 300px;
  bottom: 235px;
  z-index: 12;
}

.about-button-wrapper-4 {
  height: 60px;
}

.about-button-4 {
  width: 60px;
  height: 60px;
}


@media only screen and (max-width: 639px) {
  .about-button-wrapper-1, .about-button-wrapper-2, .about-button-wrapper-3, .about-button-wrapper-4 {
    transform: scale(0.8);
  }
}
