#navigationajovitems {
  /* background-color: rgba(0, 0, 0, 0.7) !important; */
  background-color: #0e78b6 !important;
  width: 490px !important;
  border: 3px solid #000;
  border-left: 1px solid #0e78b6;
  border-radius: 5px;
  color: #48aff0;
  position: fixed !important;
  padding: 0px !important;
  top: 97px;
  right: 60px;
  z-index: 21;
}

@media only screen and (max-width: 639px) {
  #navigationajovitems {
    transform: scale(0.5);
    left: -20px;
    top: 55px;
  }
}