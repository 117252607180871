#mainfooterbluelines {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 70%;
}

.footer-main-bg-1 {
  /* fill: #485e87; */
  fill:  transparent;
  stroke: #485e87;
}

.footer-main-bg-1,
.footer-main-bg-2,
.footer-main-bg-3 {
  stroke-miterlimit: 10;
  fill-rule: evenodd;
}

.footer-main-bg-2 {
  /*fill: #0094d9; */
  fill: transparent;
  stroke: #0094d9;
}

.footer-main-bg-3 {
  /*fill: #bbbdbf; */
  fill: transparent;
  stroke: #bbbdbf;
}
