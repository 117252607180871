#footerajov {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}
.footer-data-cls-1 {
  fill: #1b1464;
}

.footer-data-cls-2 {
  fill: #0094d9;
}

@media only screen and (max-width: 419px) {
  #footerajov {
    width: 100%;
  }
}