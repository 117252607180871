#footerotherbuilding {
  position: absolute;
  bottom: 0;
  right: 45%;
  z-index: 11;
  width: 120%;
}

.other-buildings-cls-1 {
  fill: none;
  stroke: #008bd2;
}
