.skewMain {
  position: absolute;
  top: 0;
  font-family: "Russo One", sans-serif;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 2px solid #0094d9;

  line-height: 1.7;
  color: #fff;

  transform: skewY(-15deg);
}

.bg-text-container {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  z-index: -999;
}

@keyframes text-scrolling {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

.animate-text-deux {
  animation: text-scrolling 20s linear infinite;
  will-change: transform;
  display: block;
  position: relative;
  white-space: nowrap;
}

.animate-text-deux.left {
  animation-direction: reverse;
}

span.dualScroll {
  font-size: 120px;
  color: transparent;
  -webkit-text-stroke: 1px rgba(38, 186, 255, 0.7);
  text-transform: uppercase;
  display: inline-block;
  line-height: 0.75;
  min-width: auto;
  font-weight: 800;
}

.skewMain .container {
  color: #0094d9;
  padding: 30px;
  max-width: 1000px;
  width: 100%;
  /*margin: 0 auto; */
}

.container .col {
  max-width: 600px;
  margin: 0;
}

.skewMain h1 {
  font-size: 90px;
  line-height: 60px;
  margin: 0;
}
p {
  font-size: 18px;
  font-weight: 200;
  margin: 0;
}

@media only screen and (max-width: 1920px) {
  .skewMain h1 {
    font-size: 60px;
    line-height: 60px;
  }
  .container .col {
    max-width: 500px;
  }
  p {
    font-size: 16px;
    font-weight: 200;
    margin: 0;
  }
}

@media only screen and (max-width: 1220px) {
  .container .col {
    max-width: 350px;
  }
}

@media only screen and (max-width: 639px) {
  .container .col {
    margin-top: 220px;
    max-width: none;
  }
}

@media only screen and (min-width: 1536px) and (max-width: 1919px) {
  .container .col {
    max-width: 450px;
  }
}

@media only screen and (min-width: 1921px) and (max-width: 2840px) {
  .container .col {
    max-width: 600px;
  }
}

@media only screen and (min-width: 2841px) and (max-width: 3840px) {
  .container .col {
    max-width: 1200px;
  }
}
