#headerflockbirdsajov {
  position: absolute;
  left: 90px;
  top: 20px;
  z-index: 11;
  width: 110%;
}
.flock-birds-header-cls-1 {
  fill: #485e87;
}
