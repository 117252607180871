#footerflockofbirds {
  position: absolute;
  bottom: 100px;
  right: 10%;
  z-index: 11;
  width: 80%;
}

.footer-flock-of-birds-cls-1 {
  fill: #1b1464;
}
