.ajov-contact-speak-1 {
  fill: #485e87;
}

.ajov-contact-speak-2 {
  fill: #415aa8;
}

.ajov-contact-speak-3 {
  fill: #fffffe;
}

.ajov-contact-speak-4 {
  fill: #0094d9;
}

.ajov-contact-ant-1, .ajov-contact-ant-2, .ajov-contact-ant-4 {
    fill: #fff;
  }

  .ajov-contact-ant-2 {
    stroke: #485e87;
    stroke-width: 1.28px;
  }

  .ajov-contact-ant-2, .ajov-contact-ant-4 {
    stroke-miterlimit: 3.86;
  }

  .ajov-contact-ant-3 {
    fill: #0391d5;
  }

  .ajov-contact-ant-4, .ajov-contact-ant-5, .ajov-contact-ant-6 {
    stroke: #0391d5;
  }

  .ajov-contact-ant-4 {
    stroke-width: 2.86px;
  }

  .ajov-contact-ant-5, .ajov-contact-ant-6 {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .ajov-contact-ant-5 {
    stroke-width: 1.01px;
  }

  .ajov-contact-ant-6 {
    stroke-width: 1.53px;
  }