#mainbuilding {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
  width: 70%;
}

.footer-mainbuilding-1 {
  fill: #008bd2;
}

.footer-mainbuilding-2 {
  fill: #fff;
}

.footer-mainbuilding-3 {
  fill: none;
  stroke: #008bd2;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
