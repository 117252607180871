#ajovmain {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  top: 25%;
}
.j-svg-clipped {
  position: absolute;
  top: 0;
  -webkit-clip-path: url(#svgVideoJ);
  clip-path: url(#svgVideoJ);
}
.cls-a {
  fill: #415aa8;
}
.cls-stroke-a {
  fill: none;
  stroke: #fff;
}
.cls-bg-a {
  fill: none;
}
.cls-j {
  fill: #2d338c;
}
.cls-stroke-j {
  fill: none;
  stroke: #fff;
}
.cls-bg-j {
  fill: none;
}
.cls-o {
  fill: #548ecb;
}
.cls-stroke-o {
  fill: none;
  stroke: #fff;
}
.cls-bg-o {
  fill: none;
}
.cls-v {
  fill: #53b9e9;
}
.cls-stroke-v {
  fill: none;
  stroke: #fff;
}
.cls-bg-v {
  fill: none;
}

@media only screen and (max-width: 1279px) {
  #main-background {
    top: 130px !important;
  }
}

@media only screen and (max-width: 419px) {
  #main-background {
    top: 115px !important;
    width: 90% !important;
    height: 90% !important;
  }
}
