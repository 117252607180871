#aboutcircles {
  position: absolute;
  bottom: -50px;
  left: 0;
  z-index: 8;
  width: 400px;
}
.about-circles-1 {
  fill: #0094d9;
  stroke: #485e87;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
